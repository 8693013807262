@import url(https://fonts.googleapis.com/css?family=Overpass:300,400,500,600,700,800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: Overpass, sans-serif;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  scrollbar-width: thin;
  scrollbar-color: #7f7f7f transparent;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  opacity: 0.3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  opacity: 0.3;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  background-color: #efebe9;
}

